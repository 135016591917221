
import {
  defineComponent,
  onMounted,
  onBeforeMount,
  onBeforeUpdate,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/StoreEnums";
// import { errors } from "@/store/modules/AuthModule";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Nullable } from "element-plus/es/utils/types";
export default defineComponent({
  name: "CampaignRegForm",
  components: {
    // Field,
    // Form,
    // ErrorMessage,
  },
  setup() {
    const store = useStore();
    const webURL = process.env.VUE_APP_WEB_URL;
    const appName = process.env.VUE_APP_NAME;

    let urlParams = new URLSearchParams(window.location.search);
    let event_id: any = urlParams.get("event_name");
    let event_time = urlParams.get("event_time");
    let event_id_url: string = event_id;
    if (event_id_url == "undefined") {
      event_id_url = "0";
    }
    let thankyou_message = "";
    onMounted(() => {
      console.log("loaded");
      store.dispatch(Actions.CAMPAIGNREGFORMLOAD, {}).then((resp) => {
        thankyou_message = resp;
        thankyou_message = thankyou_message.replace(
          "{event_name}",
          event_id_url
        );
      });
      if (event_id_url != "0") {
        (document.getElementById("session_name") as HTMLElement).innerHTML =
          decodeURI(event_id);
      }
    });

    const submit_campaign = (value) => {
      var name = (document.getElementById("name") as HTMLInputElement).value;
      var name_error,
        mobile_error,
        current_status_error,
        email_errors,
        city_error,
        college_error;
      var city = (document.getElementById("city") as HTMLInputElement).value;
      var college = (document.getElementById("college") as HTMLInputElement)
        .value;
      var mobile = (document.getElementById("mobile") as HTMLInputElement)
        .value;
      var email = (document.getElementById("email") as HTMLInputElement).value;
      let current_status = (
        document.querySelector(".current_status:checked") as HTMLInputElement
      ).value;
      // var form1 = (document.forms..elements as HTMLInputElement);
      console.log(current_status);
      if (name == "") {
        name_error = document.getElementById("name_error");
        if (name_error != null) {
          name_error.innerHTML = "Name Required";
        }
      }
      if (email == "") {
        email_errors = document.getElementById("email_error");
        if (email_errors != null) {
          email_errors.innerHTML = "Email Required";
        }
      }
      if (name == "" && email == "") {
        return false;
      }
      var response = {
        name: name,
        city: city,
        college: college,
        mobile: mobile,
        email: email,
        current_status: current_status,
        event_name: event_id_url,
        event_time: event_time,
      };
      email_errors = window.document.getElementById("email_errors");
      if (email_errors != null) {
        email_errors.innerHTML = "";
      }
      mobile_error = document.getElementById("mobile_error");
      if (mobile_error != null) {
        mobile_error.innerHTML = "";
      }
      name_error = document.getElementById("name_error");
      if (name_error != null) {
        name_error.innerHTML = "";
      }
      city_error = document.getElementById("city_error");
      if (city_error != null) {
        city_error.innerHTML = "";
      }
      college_error = document.getElementById("college_error");
      if (college_error != null) {
        college_error.innerHTML = "";
      }
      current_status_error = document.getElementById("current_status_error");
      if (current_status_error != null) {
        current_status_error.innerHTML = "";
      }
      (
        document.getElementById("submit_button") as HTMLInputElement
      ).setAttribute("disabled", "disabled");
      (document.getElementById("submit_button") as HTMLInputElement).innerHTML =
        "Processing...";
      store
        .dispatch(Actions.CAMPAIGNREGFORM, response)
        .then((resp) => {
          (
            document.getElementById("submit_button") as HTMLInputElement
          ).removeAttribute("disabled");
          (
            document.getElementById("submit_button") as HTMLInputElement
          ).innerHTML = "Register";
          resp = JSON.parse(resp);
          email_errors = window.document.getElementById("email_errors");
          if (email_errors != null) {
            email_errors.innerHTML = "";
          }
          mobile_error = document.getElementById("mobile_error");
          if (mobile_error != null) {
            mobile_error.innerHTML = "";
          }
          name_error = document.getElementById("name_error");
          if (name_error != null) {
            name_error.innerHTML = "";
          }
          city_error = document.getElementById("city_error");
          if (city_error != null) {
            city_error.innerHTML = "";
          }
          college_error = document.getElementById("college_error");
          if (college_error != null) {
            college_error.innerHTML = "";
          }
          current_status_error = document.getElementById(
            "current_status_error"
          );
          if (current_status_error != null) {
            current_status_error.innerHTML = "";
          }
          var camp_resp = document.getElementById("campaign_form_resp");
          if (camp_resp != null) {
            if (thankyou_message != "") {
              camp_resp.innerHTML = thankyou_message;
            } else {
              camp_resp.innerHTML = "Thank you for registering to this event.";
            }
          }
        })
        .catch((resp) => {
          (
            document.getElementById("submit_button") as HTMLInputElement
          ).removeAttribute("disabled");
          (
            document.getElementById("submit_button") as HTMLInputElement
          ).innerHTML = "Register";
          const [error] = Object.keys(store.getters.getErrors);
          if (store.getters.getErrors == "") {
            var campaign_form_resp = document.getElementById(
              "campaign_form_resp"
            ) as HTMLElement;
            if (thankyou_message != "") {
              campaign_form_resp.innerHTML =
                "<br/><br/> <center><h3>" +
                thankyou_message +
                "</h3> </center>";
            } else {
              campaign_form_resp.innerHTML =
                "<br/><br/> <center><h3>Thank you for registering to this event.</h3> </center>";
            }
            return true;
          }
          email_errors = window.document.getElementById("email_errors");
          if (email_errors != null) {
            email_errors.innerHTML = "";
          }
          mobile_error = document.getElementById("mobile_error");
          if (mobile_error != null) {
            mobile_error.innerHTML = "";
          }
          name_error = document.getElementById("name_error");
          if (name_error != null) {
            name_error.innerHTML = "";
          }
          city_error = document.getElementById("city_error");
          if (city_error != null) {
            city_error.innerHTML = "";
          }
          college_error = document.getElementById("college_error");
          if (college_error != null) {
            college_error.innerHTML = "";
          }
          current_status_error = document.getElementById(
            "current_status_error"
          );
          if (current_status_error != null) {
            current_status_error.innerHTML = "";
          }
          if (error == "email") {
            email_errors = document.getElementById("email_error");
            if (email_errors != null) {
              email_errors.innerHTML = store.getters.getErrors[error][0];
              (
                document.getElementById("submit_button") as HTMLInputElement
              ).removeAttribute("disabled");
              (
                document.getElementById("submit_button") as HTMLInputElement
              ).innerHTML = "Register";
            }
          } else {
            email_errors = document.getElementById(
              "email_error"
            ) as HTMLInputElement;
            email_errors.innerHTML = "";
          }
          if (error == "mobile") {
            mobile_error = document.getElementById("mobile_error");
            if (mobile_error != null) {
              mobile_error.innerHTML = store.getters.getErrors[error][0];
              (
                document.getElementById("submit_button") as HTMLInputElement
              ).removeAttribute("disabled");
              (
                document.getElementById("submit_button") as HTMLInputElement
              ).innerHTML = "Register";
            }
          } else {
            mobile_error = document.getElementById(
              "mobile_error"
            ) as HTMLInputElement;
            mobile_error.innerHTML = "";
          }
          if (error == "name") {
            name_error = document.getElementById("name_error");
            if (name_error != null) {
              name_error.innerHTML = store.getters.getErrors[error][0];
              (
                document.getElementById("submit_button") as HTMLInputElement
              ).removeAttribute("disabled");
              (
                document.getElementById("submit_button") as HTMLInputElement
              ).innerHTML = "Register";
            }
          } else {
            name_error = document.getElementById(
              "name_error"
            ) as HTMLInputElement;
            name_error.innerHTML = "";
          }
        });
    };
    return {
      submit_campaign,
      event_id_url,
      webURL,
      appName,
    };
  },
});
